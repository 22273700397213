import './App.css';
import rocket from './rocket.png';
import logo from './logo.png';

function App() {
    return (
        <section classname="section">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div className="container">
                <div className="title">
                    <img className="img-fluid" src={logo}></img>
                </div>
                <div>
                    <div className="bodyA">
                        <p className="bodyAText">
                            As a <b>human</b>, we bore easily by being content.<br></br>
                            We repeat our <b>cycles</b> in life and lack discomfort.<br></br>
                            This is the first attempt that we chose to <b>change</b>.<br></br>
                            Our greatest ability is to endure pain <b>under</b> the extremes of imbalances.<br></br>
                            With our incomparable tenacity and power of will, <b>pressure</b> will not crack us.
                        </p>
                    </div>
                    <div className="scene">
                        <div>
                            <img className="rocket" src={rocket}></img>
                        </div>
                    </div>
                    <div className="containerA">
                        <div class="card">
                            <div class="face face1">
                                <div class="content">
                                    <div class="icon">
                                        <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="face face2">
                                <div class="content">
                                    <h3>
                                        <a href="youtube.com" target="_blank">_Sample Title 1</a>
                                    </h3>
                                    <p>Example 1</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="face face1">
                                <div class="content">
                                    <div class="icon">
                                        <i class="fa fa-twitter-square" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="face face2">
                                <div class="content">
                                    <h3>
                                        <a href="www.twitter.com" target="_blank">Sample Title 2</a>
                                    </h3>
                                    <p>Example 2</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="face face1">
                                <div class="content">
                                    <div class="icon">
                                        <i class="fa fa-github-square" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="face face2">
                                <div class="content">
                                    <h3>
                                        <a href="www.google.com" target="_blank">Sample Title 3</a>
                                    </h3>
                                    <p>Sample 3</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default App;
